import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";
import "./header.scss";
import Logo from "../logo/logo";

function Header() {

    const navigate = useNavigate();

    const handleHeaderNavigation = (page) => {
        window.scrollTo({
            top: 0
        });
        navigate(page)
    }

    return (
        <header>
            <Box className="nav">
                <Box component="span" onClick={() => {
                    handleHeaderNavigation('/')
                }}>
                    Dessinateur projeteur
                </Box>

                <Logo/>

                <Box component="span" onClick={() => {
                    handleHeaderNavigation('drone')
                }}>
                    Télépilote de drone
                </Box>
            </Box>
        </header>
    )
}

export default Header;
import "./footer.scss"
import {Box} from "@mui/material";
import Logo from "../logo/logo";


function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer>
            <Box className="footer-nav">
                <Logo/>
                <Box component="span">
                    21 rue de Barbeneuve 37600 Loches
                    <br/>
                    06 82 22 48 44
                </Box>
            </Box>
            <Box className="footer-copyright">
                Philippe Lenoir ©{year}
            </Box>
        </footer>
    )
}

export default Footer;
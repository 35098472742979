import {Box} from "@mui/material";
import './tiles-sector.scss';
import Tile from "./tile";

function TilesSector() {

    const tilesList = [
        {'title': 'Antennes', 'imageUrl': 'assets/drone/tiles-sector/tile-antennes.jpg'},
        {'title': 'Bâtiments en péril', 'imageUrl': 'assets/drone/tiles-sector/tile-chateaux.jpg'},
        {'title': 'Bâtiments idustriels', 'imageUrl': 'assets/drone/tiles-sector/tile-batiments-industriels.jpg'},
        {'title': `Châteaux d'eau`, 'imageUrl': 'assets/drone/tiles-sector/tile-chateaux-deau.jpg'},
        {'title': 'Éoliennes', 'imageUrl': 'assets/drone/tiles-sector/tile-eoliennes.jpg'},
        {'title': 'Maisons individuelles', 'imageUrl': 'assets/drone/tiles-sector/tile-maisons-individuelles.jpg'},
        {'title': `Ouvrage d'art`, 'imageUrl': 'assets/drone/tiles-sector/tile-ouvrage-art.jpg'},
        {'title': `Génie civil`, 'imageUrl': 'assets/drone/tiles-sector/tile-ponts.jpg'},
    ];

    return(
        <Box className="tile-container">
            {tilesList.map((tile, index) => {
                return <Tile key={index} tile={tile} />
            })}
        </Box>
    )
}

export default TilesSector;
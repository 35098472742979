import "./elevated-plans.scss";
import {Box} from "@mui/material";
import Plan from "./plan";

const ElevatedPlans = () => {

    const planList = [
        {'title': 'Modèle 3D', 'imageUrl': 'assets/architecture/elevated-plans/small/model3d.jpg', 'size': 'small'},
        {'title': 'Facade', 'imageUrl': 'assets/architecture/elevated-plans/medium/facade.jpg', 'size': 'medium'},
        {'title': 'Plan de masse', 'imageUrl': 'assets/architecture/elevated-plans/medium/masse.jpg', 'size': 'medium'},
        {'title': 'Coupe', 'imageUrl': 'assets/architecture/elevated-plans/small/coupe.jpg', 'size': 'small'},
        {'title': 'Intégration', 'imageUrl': 'assets/architecture/elevated-plans/large/integration.jpg', 'size': 'large'},
    ];

    return (
        <>
            <Box className="plan-container">
                {planList.map((plan, index) => {
                    return <Plan key={index} plan={plan} />
                })}
            </Box>
        </>
    )
}

export default ElevatedPlans
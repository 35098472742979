import {Box} from "@mui/material";

function Icon(props) {


    return (
        <>
            <Box className="icon">
                {props.icon.svg}
                <h3>{props.icon.title}</h3>
                {props.mode === 'architecture' &&
                    <span>{props.icon.description}</span>
                }
            </Box>
        </>
    )
}

export default Icon
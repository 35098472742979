import {Box, Button, Typography} from "@mui/material";
import "./drone-home.scss";
import {useRef, useState} from "react";
import ActivitySlider from "../activity-slider/activity-slider";
import Contact from "../contact/contact";
import IconsDisplay from "../icons-display/icons-display";
import TilesSector from "./tile-sector/tiles-sector";

function DroneHome() {

    const heroImageUrl = "/assets/drone/hero-drone.jpg";
    const photogrametrieImageUrl = "/assets/drone/photogrametrie.jpg";
    const thermographieImageUrl = "/assets/drone/thermographie.jpg";
    const contactRef = useRef(null);


    const handleContactScroll = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const [testState, setTestState] = useState(false);

    return(
        <>
            {/* HERO IMAGE */}
            <Box className="hero-container" sx={{
                backgroundImage: `url(${heroImageUrl})`
            }}>
                <Box className="hero-text">
                    <Box>
                        <Typography variant="h1" color="primary">
                            PROPHIL&nbsp;
                            <Box component={"span"}>drone</Box>
                        </Typography>
                        <Box component="span" sx={{color:"#ffffff"}}>Préstations aériennes</Box>
                    </Box>
                    <Box>
                        <Button variant="contained" onClick={handleContactScroll}>Contact</Button>
                    </Box>
                </Box>
            </Box>

            {/* INSPECTION TECHNIQUES */}
            <Box className="container">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Inspections Techniques
                    </Typography>
                    <Box component="p">
                        Les images captées permettront un contrôle facile des édifices inspectés pour identifier
                        précisément des points de vétustés ou des défauts, et prendre si nécessaire une décision adaptée.
                    </Box>
                </Box>
                <TilesSector />
            </Box>

            {/* ICONS DIPLAY */}
            <Box className="container contained-colored">
                {/*<Box className="title-container">*/}
                {/*    <Typography variant="h2" color="secondary">*/}
                {/*        Thermographie*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                <IconsDisplay mode={'drone'}/>
            </Box>

            {/* THERMOGRAPHIE */}
            <Box className="container">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Thermographie
                    </Typography>
                </Box>
                <Box className="photogrametrie-container"
                     sx={{ backgroundImage: `url(${thermographieImageUrl})`}}>
                    <Box className="title-container">
                        <Typography variant="h3" color="secondary">
                            Restitution de bâtiments ou d’ouvrage
                        </Typography>
                        <Box component="p">
                            Mise en évidences d'éventuelles anomalies,
                            défaut d'isolation, fuite sur des canalsiations d'eau chaude,
                            recherche de nid de frelons etc...
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* PHOTOGRAMMÉTRIE */}
            <Box className="container">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Photogrammétrie
                    </Typography>
                </Box>
                <Box className="photogrametrie-container"
                     sx={{backgroundImage: `url(${photogrametrieImageUrl})`}}>
                    <Box className="title-container">
                        <Typography variant="h3" color="secondary">
                            Restitution de bâtiments ou d’ouvrage
                        </Typography>
                        <Box component="p">
                            Réalisation d’orthophotographies ou modélisation 3D
                            par des représentations topographiques
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* ACTIVITÉS */}
            <Box className="container">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Au service de nombreuses activités ou métiers
                    </Typography>
                </Box>
                <ActivitySlider slider={'drone'}/>
            </Box>

            {/* CONTACT */}
            <Box ref={contactRef}>
                <Contact />
            </Box>
        </>
    )
}

export default DroneHome;
import {Alert, Box, Button, CircularProgress, Slide, Snackbar, TextField, Typography} from "@mui/material";
import {useMemo, useState} from "react";
import './contact.scss';
import {matchIsValidTel, MuiTelInput} from "mui-tel-input";
import axios from "axios";

// SNACKBAR TRANSITION
function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
}

function Contact() {

    const emptyForm = {
        errors:{
            firstName: false,
            lastName: false,
            mail: false,
            phone: false,
            message: false,
            send: false
        },
        fields:{
            firstName: "",
            lastName: "",
            mail: "",
            phone: "",
            message: ""
        },
        sent: false
    }
    const [contactForm, setContactForm] = useState(emptyForm.fields);
    const [fieldsErrors, setFieldsErrors] = useState(emptyForm.errors);
    const [backendRequest, setBackendRequest] = useState({sendingRequest: false, error: false});

    const handleCheckPhoneNumber = (phoneNumber) => {
        setFieldsErrors({...fieldsErrors, phone: !( matchIsValidTel(phoneNumber))});
        setContactForm({...contactForm, phone: phoneNumber});
    }

    useMemo(() => {
        setFieldsErrors(emptyForm.errors);
    }, [contactForm.firstName, contactForm.lastName, contactForm.mail, contactForm.message]);

    const checkFields = () => {
        const firstNameError = contactForm.firstName === '';
        const lastNameError = contactForm.lastName === '';
        const mailError = contactForm.mail === '';
        const phoneError = contactForm.phone === '' || !matchIsValidTel(contactForm.phone);
        const messageError = contactForm.message === '';

        setFieldsErrors({
            ...fieldsErrors,
            firstName: firstNameError,
            lastName: lastNameError,
            mail: mailError,
            phone: phoneError,
            message: messageError,
        });

        if (!firstNameError && !lastNameError && !mailError && !phoneError && !messageError) {

            setBackendRequest({...backendRequest, sendingRequest: true})
            let requestError = false;
            axios
                .post(process.env.REACT_APP_BACKEND_BASE_URL + '/mail/contact', contactForm)
                .then((response) => {
                    console.log(response.data);
                    response.status === 200 && setContactForm({...contactForm, sent: true})
                })
                .catch(error => {
                    console.log(error.response.data)
                    requestError = true;
                })
                .finally(() => {
                    setBackendRequest({sendingRequest: false, error: requestError})
                });
        }
    }

    return(
        <>
            <Box className="container" id="contact">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Soyez le pilote de vos projets architecturaux.
                    </Typography>
                </Box>
                <Box>
                    {
                        contactForm.sent ?
                        <Box>
                            Merci pour votre message.
                        </Box>
                        :
                        <form action="">
                            <Box className="double-champs">
                                <TextField
                                    label="Prénom"
                                    id="firstName"
                                    value={contactForm.firstName}
                                    onChange={(e) => setContactForm({
                                        ...contactForm,
                                        firstName: e.target.value})}
                                    error={fieldsErrors.firstName}
                                    helperText={fieldsErrors.firstName && 'Ce champs est obligatoire'}
                                />
                                <TextField
                                    label="Nom"
                                    id="lastName"
                                    value={contactForm.lastName}
                                    onChange={(e) => setContactForm({
                                        ...contactForm,
                                        lastName: e.target.value})}
                                    error={fieldsErrors.lastName}
                                    helperText={fieldsErrors.lastName && 'Ce champs est obligatoire'}
                                />
                            </Box>
                            <Box className="double-champs">
                                <TextField
                                    label="Mail"
                                    id="mail"
                                    value={contactForm.mail}
                                    onChange={(e) => setContactForm({
                                        ...contactForm,
                                        mail: e.target.value}
                                    )}
                                    error={fieldsErrors.mail}
                                    helperText={fieldsErrors.mail && 'Ce champs est obligatoire'}
                                />
                                <MuiTelInput
                                    label="Téléphone"
                                    value={contactForm.phone}
                                    defaultCountry="FR"
                                    onChange={handleCheckPhoneNumber}
                                    error={fieldsErrors.phone}
                                    helperText={fieldsErrors.phone && (contactForm.phone === '' && 'Ce champs est obligatoire')}
                                />
                            </Box>
                            <TextField
                                label="Votre message"
                                id="message"
                                sx={{width: '100%'}}
                                multiline
                                rows={4}
                                value={contactForm.message}
                                onChange={(e) => setContactForm({
                                    ...contactForm,
                                    message: e.target.value}
                                )}
                                error={fieldsErrors.message}
                                helperText={fieldsErrors.message && 'Ce champs est obligatoire'}
                            />
                            {
                                backendRequest.sendingRequest ?
                                    <CircularProgress />
                                    :
                                    <Button onClick={checkFields} variant="contained" sx={{marginTop: "40px"}}>Contact</Button>
                            }
                        </form>
                    }
                </Box>
            </Box>

            <Snackbar
                open={backendRequest.error}
                autoHideDuration={6000}
                onClose={() => setBackendRequest({...backendRequest, error: false})}
                anchorOrigin={{vertical: "top",horizontal: "right"}}
                TransitionComponent={SlideTransition}
                key={SlideTransition.name}>
                <Alert
                    onClose={() => setBackendRequest({...backendRequest, error: false})}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Une erreur est survenue avec le fomulaire de contact. Réssayez plus tard.
                </Alert>
            </Snackbar>
        </>
    )
}
export default Contact;
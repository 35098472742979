import Item from "./item";
import "./activity-slider.scss"
import {Box} from "@mui/material";
import {useRef} from "react";


function ActivitySlider(props) {

    const items = {
        archi: [
            {'title': 'Architectes', 'imageUrl': 'assets/activities/architectes.jpg', 'color': '#000000'},
            // {'title': 'Collectivités', 'imageUrl': 'assets/activities/collectivités.jpg', 'color': '#FFFFFF'},
            {'title': 'Entreprises du BTP', 'imageUrl': 'assets/activities/btp.jpg', 'color': '#FFFFFF'},
            {'title': `Maitres d'oeuvre`, 'imageUrl': 'assets/activities/agriculteurs.jpg', 'color': '#FFFFFF'},
            {'title': 'Particuliers', 'imageUrl': 'assets/activities/particuliers.jpg', 'color': '#000000'},
        ],
        drone: [
            {'title': 'Agents immobilier', 'imageUrl': 'assets/activities/agents-immobiliers.jpg', 'color': '#FFFFFF'},
            {'title': 'Agriculteurs', 'imageUrl': 'assets/activities/agriculteurs.jpg', 'color': '#FFFFFF'},
            {'title': 'Archéologues', 'imageUrl': 'assets/activities/archéologues.jpg', 'color': '#FFFFFF'},
            {'title': 'Architectes', 'imageUrl': 'assets/activities/architectes.jpg', 'color': '#000000'},
            {'title': 'Assureurs', 'imageUrl': 'assets/activities/assureurs.jpg', 'color': '#FFFFFF'},
            // {'title': 'Collectivités', 'imageUrl': 'assets/activities/collectivités.jpg', 'color': '#FFFFFF'},
            {'title': 'Couvreurs', 'imageUrl': 'assets/activities/couvreurs.jpg', 'color': '#FFFFFF'},
            {'title': 'Entreprises du BTP', 'imageUrl': 'assets/activities/btp.jpg', 'color': '#FFFFFF'},
            {'title': 'Exploitants forêstiers', 'imageUrl': 'assets/activities/forets.jpg', 'color': '#FFFFFF'},
            {'title': 'Fédération de chasse', 'imageUrl': 'assets/activities/chasse.jpg', 'color': '#FFFFFF'},
            {'title': 'Géomètres', 'imageUrl': 'assets/activities/geologues.jpg', 'color': '#FFFFFF'},
            {'title': 'Industriels', 'imageUrl': 'assets/activities/industriels.jpg', 'color': '#FFFFFF'},
            {'title': 'Photographes', 'imageUrl': 'assets/activities/photographes.jpg', 'color': '#FFFFFF'},
            // {'title': 'Policiers, Gendarmes, Pompiers', 'imageUrl': 'assets/activities/pompiers.jpg', 'color': '#FFFFFF'},
            {'title': `Service de l'état`, 'imageUrl': 'assets/activities/services-etat.jpg', 'color': '#FFFFFF'},
            {'title': 'Thermiciens', 'imageUrl': 'assets/activities/thermiciens.jpg', 'color': '#FFFFFF'}
        ]
    }
    /*
    Secteur Immobilier et Construction :
        Agents immobiliers
        Couvreurs
        Entreprises du BTP
        Architectes

    Agriculture, Forêt et Environnement :
        Agriculteurs
        Exploitants forestiers
        Fédération de chasse

    Arts, Culture et Patrimoine :
        Archéologues
        Photographes

    Secteur Financier et Assurance :
        Assureurs

    Géomatique et Topographie :
        Géomètres

    Sécurité, Services d'Urgence et Administration Publique :
        Policiers
        Gendarmes
        Pompiers
        Service de l'État
    */

    const containerRef = useRef(null);
    /*useEffect(() => {
        Draggable.create(containerRef.current, {
            bounds: '.slider-container',
            edgeResistance: 0.8,
            type: 'x',
            inertia: true, //faut payer ntm
        });

    }, []);*/

    return (
        <>
            <Box className="slider-container">
                <Box  ref={containerRef} className="slider-items">
                    {items[props.slider].map((item, index) => {
                        return <Item item={item} key={index}/>
                    })}
                </Box>
            </Box>
        </>
    );

}

export default ActivitySlider;
import {Box} from "@mui/material";

const Plan = (props) => {

    const plan = props.plan
    const plansSize = {
        small: {
            containerWidth: 400,
            backgroundWidth: 350,
            imageWidth: 'calc(100% - 50px)'
        },
        medium: {
            containerWidth: 750,
            backgroundWidth: 350,
            imageWidth: 'calc(100% - 50px)'
        },
        large: {
            containerWidth: 1200,
            backgroundWidth: 350,
            imageWidth: 'calc(100% - 50px)'
        }
    }


    return (
        <>
            <Box className="plan" sx={{width: plansSize[plan.size].containerWidth}}>
                <Box className="plan-image"
                     sx={{
                         width: plansSize[plan.size].imageWidth,
                         backgroundImage: `url(${plan.imageUrl})`}}/>
                <Box className="plan-background"
                     sx={{
                         width: plansSize[plan.size].backgroundWidth,
                         backgroundColor: plan.size === 'small' ? '#0CA7FF' : '#114563'}}>
                    <Box component="span">{plan.title}</Box>
                </Box>
            </Box>
        </>
    )
}

export default Plan
import {Box} from "@mui/material";

function Tile(props) {

    const tile = props.tile;

    return(
        <Box className="tile" sx={{backgroundImage: `url(${tile.imageUrl})`}}>
            <Box component="span">
                {tile.title}
            </Box>
            <Box className="text-background"></Box>
        </Box>
    )
}

export default Tile;
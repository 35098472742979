import {Box} from "@mui/material";
import './logo.scss';

function Logo() {
    return(
        <Box component="span" className='main-logo'>
            Philipe Lenoir
        </Box>
    )
}

export default Logo;
import './App.scss';
import {createTheme, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import DroneHome from "./components/drone-home/drone-home";
import ArchiHome from "./components/archi-home/archi-home";

const theme = createTheme({
    palette: {
        primary: {
            main: '#0CA7FF',
            // light: will be calculated from palette.primary.main,
            dark: '#114563',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#114563',
            // light: will be calculated from palette.primary.main,
            // dark: will be calculated from palette.primary.main,
            contrastText: '#ffffff'
        },
    },
    typography: {
        h1: {
            fontSize: '50px',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '30px',
            fontWeight: '600',
            marginBottom: '10px',
        },
        h3: {
            fontSize: '25px',
            fontWeight: '600',
            marginBottom: '10px',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                fullWidth: true
            },
            styleOverrides: {
                root: {
                    width: '300px'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                size: 'medium',
            },
            styleOverrides: {
                root: {
                    padding: '6px 50px'
                },
            }
        },
        MuiRating: {
            defaultProps: {

            },
            styleOverrides: {
                root: {
                    '& .MuiRating-iconFilled': {
                        color: '#98E089',
                    },
                    '& .MuiRating-iconHover': {
                        color: '#CC8E69',
                    },
                }
            }
        }
    }
});


function App() {
  return (
      <BrowserRouter>
          <ThemeProvider theme={theme}>
              <Header/>
              <Routes>
                  <Route path="/" element={<ArchiHome />} />
                  <Route path="/drone" element={<DroneHome />} />

                  {/* Route pour capturer toutes les routes non définies */}
                  {/*<Route path="*" element={<NotFound />} />*/}
              </Routes>
              <Footer/>
          </ThemeProvider>
      </BrowserRouter>
  );
}

export default App;

import {Box} from "@mui/material";

function ItemSlider(props) {

    const item = props.item;

    return(
        <Box className={"item"} sx={{ backgroundImage: `url(${item.imageUrl})` }}>
            <Box component="span" sx={{ color: item.color }}>
                {item.title}
            </Box>
        </Box>
    )
}

export default ItemSlider;
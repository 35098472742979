import {Box, Button, Typography} from "@mui/material";
import "./archi-home.scss";
import {useRef} from "react";
import ActivitySlider from "../activity-slider/activity-slider";
import Contact from "../contact/contact";
import ElevatedPlans from "./elevated-plan/elevated-plans";
import IconsDisplay from "../icons-display/icons-display";

function ArchiHome() {

    const heroImageUrl = "/assets/architecture/hero-architecture2.jpg";
    const contactRef = useRef(null);


    const handleContactScroll = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return(
        <>
            {/* HERO IMAGE */}
            <Box className="hero-container" sx={{backgroundImage: `url(${heroImageUrl})`}}>
                <Box className="black-block"></Box>
                <Box className="hero-text colored">
                    <Box>
                        <Typography variant="h1" color="primary">Philippe Lenoir</Typography>
                        <Box component="span" sx={{color:"#000000"}}>Dessinateur projeteur</Box>
                    </Box>
                    <Box>
                        <Button variant="contained" onClick={handleContactScroll}>Contact</Button>
                    </Box>
                </Box>
            </Box>

            {/* MES ACTIVITÉES ARCHITECTURALES */}
            <Box className="container">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Mes activitées architecturales
                    </Typography>
                </Box>
                <IconsDisplay mode={'architecture'}/>
            </Box>

            {/* ARCHITECTURES CARDS */}
            <Box className="container">
                <ElevatedPlans />
            </Box>

            {/* ACTIVITÉS */}
            <Box className="container">
                <Box className="title-container">
                    <Typography variant="h2" color="secondary">
                        Au service de nombreuses activités ou métiers
                    </Typography>
                </Box>
                <ActivitySlider slider={'archi'} />
            </Box>

            {/* CONTACT */}
            <Box ref={contactRef}>
                <Contact />
            </Box>
        </>
    )
}

export default ArchiHome;